import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Server basics";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "server-basics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#server-basics",
        "aria-label": "server basics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server basics`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ports"
        }}>{`Ports`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#services"
        }}>{`Services`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#path-patterns"
        }}>{`Path patterns`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#per-service-configuration"
        }}>{`Per service configuration`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ssltls"
        }}>{`SSL/TLS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#proxy-protocol"
        }}>{`PROXY protocol`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#serving-http-and-https-on-the-same-port"
        }}>{`Serving HTTP and HTTPS on the same port`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#virtual-hosts"
        }}>{`Virtual hosts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#getting-an-ip-address-of-a-client-who-initiated-a-request"
        }}>{`Getting an IP address of a client who initiated a request`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#see-also"
        }}>{`See also`}</a></li>
    </ul>
    <p>{`To start a server, you need to build it first. Use `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;

ServerBuilder sb = Server.builder();
// TODO: Configure your server here.
Server server = sb.build();
CompletableFuture<Void> future = server.start();
// Wait until the server is ready.
future.join();
`}</code></pre>
    <h2 {...{
      "id": "ports",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ports",
        "aria-label": "ports permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ports`}</h2>
    <p>{`To serve anything, you need to specify which TCP/IP port you want to bind onto:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
// Configure an HTTP port.
sb.http(8080);
// TODO: Add your services here.
Server server = sb.build();
CompletableFuture<Void> future = server.start();
future.join();
`}</code></pre>
    <h2 {...{
      "id": "services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#services",
        "aria-label": "services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Services`}</h2>
    <p>{`Even if we opened a port, it's of no use if we didn't bind any services to them. Let's add some:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpRequest;
import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.HttpStatus;
import com.linecorp.armeria.common.MediaType;
import com.linecorp.armeria.common.MediaTypeNames;
import com.linecorp.armeria.common.QueryParams;

import com.linecorp.armeria.server.AbstractHttpService;
import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.ServiceRequestContext;
import com.linecorp.armeria.server.annotation.Consumes;
import com.linecorp.armeria.server.annotation.Default;
import com.linecorp.armeria.server.annotation.Get;
import com.linecorp.armeria.server.annotation.Param;
import com.linecorp.armeria.server.annotation.Post;
import com.linecorp.armeria.server.annotation.Produces;
import com.linecorp.armeria.server.logging.LoggingService;

ServerBuilder sb = Server.builder();
sb.http(8080);

// Add a simple 'Hello, world!' service.
sb.service("/", (ctx, req) -> HttpResponse.of("Hello, world!"));

// Using path variables:
sb.service("/greet/{name}", new AbstractHttpService() {
    @Override
    protected HttpResponse doGet(ServiceRequestContext ctx, HttpRequest req) {
        String name = ctx.pathParam("name");
        return HttpResponse.of("Hello, %s!", name);
    }
}.decorate(LoggingService.newDecorator())); // Enable logging

// Using an annotated service object:
sb.annotatedService(new Object() {
    @Get("/greet2/:name") // \`:name\` style is also available
    public HttpResponse greet(@Param("name") String name) {
        return HttpResponse.of("Hello, %s!", name);
    }
});

// Just in case your name contains a slash:
sb.serviceUnder("/greet3", (ctx, req) -> {
    String path = ctx.mappedPath();  // Get the path without the prefix ('/greet3')
    String name = path.substring(1); // Strip the leading slash ('/')
    return HttpResponse.of("Hello, %s!", name);
});

// Using an annotated service object:
sb.annotatedService(new Object() {
    @Get("regex:^/greet4/(?<name>.*)$")
    public HttpResponse greet(@Param("name") String name) {
        return HttpResponse.of("Hello, %s!", name);
    }
});

// Using a query parameter (e.g. /greet5?name=alice) on an annotated service object:
sb.annotatedService(new Object() {
    @Get("/greet5")
    public HttpResponse greet(@Param("name") String name,
                              @Param("title") @Default("Mr.") String title) {
        // "Mr." is used by default if there is no title parameter in the request.
        return HttpResponse.of("Hello, %s %s!", title, name);
    }
});

// Getting a map of query parameters on an annotated service object:
sb.annotatedService(new Object() {
    @Get("/greet6")
    public HttpResponse greet(QueryParams params) {
        return HttpResponse.of("Hello, %s!", params.get("name"));
    }
});

// Using media type negotiation:
sb.annotatedService(new Object() {
    @Get("/greet7")
    @Produces(MediaTypeNames.JSON_UTF_8)
    public HttpResponse greetGet(@Param("name") String name) {
        return HttpResponse.of(HttpStatus.OK, MediaType.JSON_UTF_8,
                               "{\\"name\\":\\"%s\\"}", name);
    }

    @Post("/greet7")
    @Consumes(MediaTypeNames.FORM_DATA)
    public HttpResponse greetPost(@Param("name") String name) {
        return HttpResponse.of(HttpStatus.OK);
    }
});

Server server = sb.build();
CompletableFuture<Void> future = server.start();
future.join();
`}</code></pre>
    <p>{`As described in the example, `}<inlineCode parentName="p">{`service()`}</inlineCode>{` and `}<inlineCode parentName="p">{`serviceUnder()`}</inlineCode>{` perform an exact match and a prefix match
on a request path respectively. `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{` also provides advanced path mapping such as regex and
glob pattern matching.`}</p>
    <p>{`Also, we decorated the second service using `}<a parentName="p" {...{
        "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
      }}>{`type://LoggingService`}</a>{`, which logs all requests and responses.
You might be interested in decorating a service using other decorators, for example to gather metrics.`}</p>
    <p>{`You can also use an arbitrary object that's annotated by the `}<a parentName="p" {...{
        "href": "type://@Path:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Path.html"
      }}>{`type://@Path`}</a>{` annotation
using `}<inlineCode parentName="p">{`annotatedService()`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "path-patterns",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#path-patterns",
        "aria-label": "path patterns permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Path patterns`}</h2>
    <p>{`You can use the following path patterns to map an HTTP request path to a service or a decorator.`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Pattern`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Example`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Exact match`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`/foo/bar`}</inlineCode>{` or `}<inlineCode parentName="p">{`exact:/foo/bar`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Curly-brace style path variables`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`/users/{userId}`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Colon style path variables`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`/list/:productType/by/:ordering`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Prefix match`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`prefix:/files`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Glob pattern`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`glob:/*/downloads/**`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Regular expression`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`regex:^/files/(?<filePath>.\\*)$`}</inlineCode></p></td></tr></tbody></table>
    <Tip mdxType="Tip">
By default, colon style path variables assume that a path segment starting with a ':' is a parameter name.
If the colon starting a path segment should signify a literal, you can prefix the ':' with '\\'.
      <p>{`e.g.`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`/foo/\\\\:colon:`}</inlineCode>{` will match a request with path `}<inlineCode parentName="li">{`/foo/:colon:`}</inlineCode>{`.`}
          <ul parentName="li">
            <li parentName="ul">{`Note that only the first colon starting the segment should be escaped.`}</li>
          </ul>
        </li>
        <li parentName="ul"><inlineCode parentName="li">{`/foo/:colon`}</inlineCode>{` will match a request with path `}<inlineCode parentName="li">{`/foo/bar`}</inlineCode>{`. The path parameter 'colon' will be bound to 'bar'.`}</li>
      </ul>
    </Tip>
    <h2 {...{
      "id": "per-service-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#per-service-configuration",
        "aria-label": "per service configuration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Per service configuration`}</h2>
    <p>{`The examples above are just mapping the path of an HTTP request on a service. If you want to set configuration
for a specific service, you can use fluent API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
sb.route()                    // Configure the service.
  .post("/foo/bar")           // Matched when the path is "/foo/bar" and the method is POST.
  .consumes(MediaType.JSON)   // Matched when the "content-type" header is "application/json".
  .produces(MediaType.JSON)   // Matched when the "accept" headers is "application/json".
  .matchesHeaders("baz=qux")  // Matched when the "baz" header is "qux".
  .matchesParams("quux=quuz") // Matched when the "quux" parameter is "quuz".
  .requestTimeoutMillis(5000)
  .maxRequestLength(8192)
  .verboseResponses(true)
  .build((ctx, req) -> HttpResponse.of(OK)); // Should call to finish and return to the ServerBuilder.
`}</code></pre>
    <p>{`Or use a `}<inlineCode parentName="p">{`Consumer`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpMethod;

ServerBuilder sb = Server.builder();
sb.withRoute(builder -> builder.path("/baz") // Matched when the path is "/baz".
                               // Matched when the method is GET or POST.
                               .methods(HttpMethod.GET, HttpMethod.POST)
                               ...
                               .build((ctx, req) -> HttpResponse.of(OK)));
`}</code></pre>
    <h2 {...{
      "id": "ssltls",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ssltls",
        "aria-label": "ssltls permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSL/TLS`}</h2>
    <p>{`You can also add an HTTPS port with your certificate and its private key files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
sb.https(8443)
  .tls(new File("certificate.crt"), new File("private.key"), "myPassphrase");
...
`}</code></pre>
    <h2 {...{
      "id": "proxy-protocol",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#proxy-protocol",
        "aria-label": "proxy protocol permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PROXY protocol`}</h2>
    <p>{`Armeria supports both text (v1) and binary (v2) versions of
`}<a parentName="p" {...{
        "href": "https://www.haproxy.org/download/1.8/doc/proxy-protocol.txt"
      }}>{`PROXY protocol`}</a>{`.
If your server is behind a load balancer such as `}<a parentName="p" {...{
        "href": "https://www.haproxy.org/"
      }}>{`HAProxy`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/elasticloadbalancing/"
      }}>{`AWS ELB`}</a>{`, you could consider enabling the PROXY protocol:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import static com.linecorp.armeria.common.SessionProtocol.HTTP;
import static com.linecorp.armeria.common.SessionProtocol.HTTPS;
import static com.linecorp.armeria.common.SessionProtocol.PROXY;

ServerBuilder sb = Server.builder();
sb.port(8080, PROXY, HTTP);
sb.port(8443, PROXY, HTTPS);
...
`}</code></pre>
    <h2 {...{
      "id": "serving-http-and-https-on-the-same-port",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#serving-http-and-https-on-the-same-port",
        "aria-label": "serving http and https on the same port permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Serving HTTP and HTTPS on the same port`}</h2>
    <p>{`For whatever reason, you may have to serve both HTTP and HTTPS on the same port. Armeria is one of the few
implementations that supports port unification:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
sb.port(8888, HTTP, HTTPS);
// Enable PROXY protocol, too.
sb.port(9999, PROXY, HTTP, HTTPS);
...
`}</code></pre>
    <h2 {...{
      "id": "virtual-hosts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#virtual-hosts",
        "aria-label": "virtual hosts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Virtual hosts`}</h2>
    <p>{`Use `}<a parentName="p" {...{
        "href": "type://ServerBuilder#virtualHost(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#virtualHost(java.lang.String)"
      }}>{`type://ServerBuilder#virtualHost(String)`}</a>{` to configure `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Virtual_hosting#Name-based"
      }}>{`a name-based virtual host`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
// Configure foo.com.
sb.virtualHost("foo.com")
  .service(...)
  .tls(...)
  .and() // Configure *.bar.com.
  .virtualHost("*.bar.com")
  .service(...)
  .tls(...)
  .and() // Configure the default virtual host.
  .service(...)
  .tls(...);
...
`}</code></pre>
    <h2 {...{
      "id": "getting-an-ip-address-of-a-client-who-initiated-a-request",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getting-an-ip-address-of-a-client-who-initiated-a-request",
        "aria-label": "getting an ip address of a client who initiated a request permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting an IP address of a client who initiated a request`}</h2>
    <p>{`You may want to get an IP address of a client who initiated a request in your service. In that case,
you can use `}<a parentName="p" {...{
        "href": "type://ServiceRequestContext#clientAddress():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#clientAddress()"
      }}>{`type://ServiceRequestContext#clientAddress()`}</a>{`. But you need to configure
your `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{` before doing that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.util.InetAddressPredicates;
import com.linecorp.armeria.server.ClientAddressSource;

ServerBuilder sb = Server.builder();

// Configure a filter which evaluates whether an address of a remote endpoint is
// trusted. If unspecified, no remote endpoint is trusted.
// e.g. servers who have an IP address in 10.0.0.0/8.
sb.clientAddressTrustedProxyFilter(InetAddressPredicates.ofCidr("10.0.0.0/8"));

// Configure a filter which evaluates whether an address can be used as
// a client address. If unspecified, any address would be accepted.
// e.g. public addresses
sb.clientAddressFilter(address -> !address.isSiteLocalAddress());

// Configure a list of sources which are used to determine where to look for
// the client address, in the order of preference. If unspecified, 'Forwarded',
// 'X-Forwarded-For' and the source address of a PROXY protocol header would be used.
sb.clientAddressSources(ClientAddressSource.ofHeader(HttpHeaderNames.FORWARDED),
                        ClientAddressSource.ofHeader(HttpHeaderNames.X_FORWARDED_FOR),
                        ClientAddressSource.ofProxyProtocol());

// Get an IP address of a client who initiated a request.
sb.service("/", (ctx, res) ->
        HttpResponse.of("A request was initiated by %s!", 
                        ctx.clientAddress().getHostAddress()));
`}</code></pre>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/server-decorator"
        }}>{`Decorating a service`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      